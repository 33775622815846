import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Typography,
  Button,
  Box,
  IconButton,
} from '@mui/material';
 
import { useNavigate } from 'react-router-dom'; 
import DialogBox from '../../components/Dialog';
import IOAlerts from '../../components/Alert';
import IOTable from '../../components/Table';
import { Edit, Delete } from '@mui/icons-material';

export interface Stage {
  id: number;
  code: string;
  fullName: string;
  email?: string;
  phoneNumber: string;
  role: string;
}
const UsersList: React.FC = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState<Stage[]>([]);
  const [search, setSearch] = useState(''); 
  const [dialog, setDialog] = useState({
    open: false,
    title: '',
    message: '',
    actions: [
      {
        label: 'cancel',
        handler: () => {},
      },
      {
        label: 'yes',
        handler: () => {},
      },
    ],
  });
  const [alert, setAlert] = useState<{
    open: boolean;
    severity: 'success' | 'error' | 'info' | 'warning';
    message: string;
  }>({
    open: false,
    severity: 'info',
    message: 'Loading...',
  });

  const closeDialogHandler = () => {
    setDialog((prevState) => ({ ...prevState, open: false }));
  };

  useEffect(() => {
    setDialog((prevState) => ({
      ...prevState,
      actions: [{ label: 'cancel', handler: closeDialogHandler }],
    }));
    //fetchCategories();
  }, []);

  const fetchCategories = async () => {
    setAlert({ open: true, message: 'Loading...', severity: 'info' });
    // const data = await getCategories();
    // setCategories(data);
    setAlert({ open: false, message: '', severity: 'info' });
  };

  const handleDelete = async (id: number) => {
    closeDialogHandler();
    setAlert({ open: true, message: 'Deleting...', severity: 'info' });
   // await deleteCategory(id.toString());
    //setCategories(categories.filter((category) => category.id !== id));
    setAlert({
      open: true,
      message: 'Deleted successfully',
      severity: 'success',
    });
  };

  const handleForm = (id?: string) => {
    navigate('form', { state: { id } });
  };

 
  const errorHandler = (error: Error) => {
    setAlert({ open: true, message: error.message, severity: 'error' });
  };

  const filteredTypes = categories
    .filter((category) =>
      category.fullName.toLowerCase().includes(search.toLowerCase()),
    )
    .sort((a, b) => a.fullName.localeCompare(b.fullName)
    );

  const headCells = [
    {
      id: 'id' as const,
      numeric: false,
      disablePadding: true,
      label: 'ID',
    },
    {
      id: 'name' as const,
      numeric: false,
      disablePadding: true,
      label: 'Full Name',
    },
    {
      id: 'email' as const,
      numeric: false,
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'phone' as const,
      numeric: false,
      disablePadding: false,
      label: 'Phone Number',
    },
    {
      id: 'role' as const,
      numeric: false,
      disablePadding: false,
      label: 'Role',
    },
    {
      id: 'actions' as const,
      numeric: false,
      disablePadding: false,
      label: 'Actions',
    },
  ] as const;

  const rows = filteredTypes.map((type: Stage) => ({
    id: type.id,
    name: type.fullName,
    email: type.email,
      actions: (
        <>
          <IconButton
            aria-label="edit"
            onClick={() => handleForm(type?.id.toString())}
          >
            <Edit />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() =>
              setDialog((prevState) => ({
                ...prevState,
                open: true,
                title: 'Delete',
                message: 'Are you sure you want to delete?',
                actions: [
                  { label: 'cancel', handler: closeDialogHandler },
                  {
                    label: 'yes',
                    handler: () => handleDelete(type.id),
                  },
                ],
              }))
            }
          >
            <Delete />
          </IconButton>
        </>
      ),
    }));

  const getDialog = () => {
    return (
      <DialogBox
        open={dialog.open}
        title={dialog.title}
        content={dialog.message}
        actions={dialog.actions}
        closeHandler={closeDialogHandler}
      />
    );
  };

  return (
    <>
      <IOAlerts alert={alert} setAlert={setAlert} />
      <Container>
        <Typography variant="h5">Users List</Typography>
        <Box display="flex" alignItems="center" mb={2}>
          <TextField
            label="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            fullWidth
            margin="normal"
            size="small"
          />
          <Button
            size="small"
            onClick={() => handleForm()}
            style={{ marginLeft: 16 }}
          >
            Add
          </Button>
        </Box>
        <IOTable
          rows={rows}
          headCells={headCells}
          onDelete={(selected: readonly number[]) => {
            selected.forEach((id) => handleDelete(id));
          }}
        />
        {getDialog()}
      </Container>
    </>
  );
};

export default UsersList;
 