import axios from "axios";
const { REACT_APP_BASEURL = "http://localhost:5000", REACT_APP_STAGE = "" } = process.env; //  
export const axiosInstance = axios.create({
  baseURL: `${REACT_APP_BASEURL}/${REACT_APP_STAGE}`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  timeout: 10_000,
});
console.log("axiosInstance",axiosInstance);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error("API error", error);
    return Promise.reject(error);
  }
);
