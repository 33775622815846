import { Box, Alert, Collapse, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface IOAlertsProps {
  setAlert: React.Dispatch<
    React.SetStateAction<{
      open: boolean;

      severity: 'success' | 'info' | 'warning' | 'error';

      message: string;
    }>
  >;

  alert: {
    open: boolean;

    severity: 'success' | 'info' | 'warning' | 'error';

    message: string;
  };
}

export default function iOAlerts(props: IOAlertsProps) {
  const { alert, setAlert } = props;
  const { open, severity, message } = alert;
  return (
    <Box sx={{ width: '100%' }}>
      <Collapse in={open}>
        <Alert
          variant="filled"
          severity={severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlert({ ...alert, open: false, message: '' });
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      </Collapse>
    </Box>
  );
}
