import React from 'react';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import { Formik, Form, Field, FieldProps } from 'formik';
import * as Yup from 'yup';
import './index.css';

interface FormProps<T> {
  title: string;
  initialValues: any;
  validationSchema: Yup.ObjectSchema<any>;
  onSubmitHandler: (
    values: T,
    event?:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement>,
  ) => void;
  onBackHandler: () => void;
  mode: 'add' | 'update';
}

const FormCtrl = <T extends Record<string, any>>(props: FormProps<T>) => {
  const {
    title,
    initialValues,
    validationSchema,
    onSubmitHandler,
    onBackHandler,
    mode,
  } = props;
  
  return (
    <Container maxWidth={false}>
      <Box sx={{ maxWidth: '600px', margin: '10px auto' }}>
        <Typography
          variant="h5"
          sx={{ margin: '15px  auto', textAlign: 'center' }}
        >
          {mode === 'add' ? `Add ${title}` : `Update ${title}`}
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmitHandler(values);
            setSubmitting(false);
          }}
          enableReinitialize
        >
          {({ resetForm }) => (
            <Form>
              {Object.keys(initialValues).map((field, index) => (
                <Box key={index} className="field-container">
                  <Field name={field}>
                    {({ field, meta }: FieldProps) => (
                      <TextField
                        {...field}
                        label={field.name.split(/(?=[A-Z])/).join(' ')}
                        fullWidth
                        variant="outlined"
                        size="small"
                        error={meta.touched && !!meta.error}
                        helperText={meta.touched && meta.error}
                      />
                    )}
                  </Field>
                </Box>
              ))}

              <Box className="button-container">
                <Button type="submit" variant="contained" color="success">
                  {mode === 'add' ? 'Add' : 'Update'}
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  color="primary"
                  onClick={() => resetForm()}
                  className="reset-button"
                >
                  Reset
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  color="error"
                  onClick={onBackHandler}
                >
                  Back
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Container>
  );
};

export default FormCtrl;