import React, { useRef } from 'react';
import Papa from 'papaparse';
import { Button, Alert } from '@mui/material';

interface UploadCSVProps {
  fileHandler: (data: any) => void;
  errorHandler: (error: Error) => void;
  fileKeys: string[];
}

const UploadCSV: React.FC<UploadCSVProps> = (props: UploadCSVProps) => {
  const { fileHandler, fileKeys, errorHandler } = props;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleUploadCSV = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      Papa.parse<Papa.ParseResult<any>>(file, {
        delimiter: ',',
        header: true,
        complete: (results: Papa.ParseResult<any>) => {
          const data = results.data;
          const validData = data.filter((item: any) => {
            const keys = Object.keys(item);
            return (
              keys.length === 2 &&
              keys.includes('categories') &&
              keys.includes('description') &&
              item.categories.trim() !== ''
            );
          });

          if (validData.length !== data.length) {
            errorHandler(new Error('Invalid data in CSV file'));
          } else {
            fileHandler(validData);
          }
        },
        error: (error: Error, file: File) => {
          const parseError = error as unknown as Papa.ParseError;
          errorHandler(new Error(parseError.message));
        },
        skipEmptyLines: true,
        transformHeader: (header) => header.trim(),
      });
    }
  };
  return (
    <Button
      size="small"
      style={{ marginLeft: 16 }}
      onClick={() => fileInputRef.current?.click()}
    >
      Upload
      <input
        type="file"
        accept=".csv"
        hidden
        ref={fileInputRef}
        onChange={handleUploadCSV}
      />
    </Button>
  );
};

export default UploadCSV;
