import { axiosInstance } from './api';
import { CSVParsedData } from '../../pages/MetaData/Categories';
const categoriesBaseURL = '/d-categories';

export const getCategories = async () => {
  const response = await axiosInstance.get(categoriesBaseURL);
  return response.data;
};
export const getCategory = async (filters: { id?: string, name?: string }) => {
  const queryParams = new URLSearchParams(filters).toString();
  const response = await axiosInstance.get(`${categoriesBaseURL}?${queryParams}`);
  return response.data;
};
export const addCategory = async (category: { name: string, description: string }) => {
  const response = await axiosInstance.post(categoriesBaseURL, category);
  return response.data;
};

export const updateCategory = async (id: string, category: { name: string, description: string }) => {
  const queryParams = new URLSearchParams({id}).toString();
  const response = await axiosInstance.put(`${categoriesBaseURL}?${queryParams}`, category);
  return response.data;
};

export const deleteCategory = async (id: string) => {
  const queryParams = new URLSearchParams({id}).toString();
  const response = await axiosInstance.delete(`${categoriesBaseURL}?${queryParams}`);
  return response.data;
};

export const uploadCSV = async (category: CSVParsedData[]) => {
  const response = await axiosInstance.post(categoriesBaseURL, category);
  return response.data;
};