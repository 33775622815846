import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//import { AuthProvider } from "../context/AuthContext";
import ProtectedRoute from "./ProtectedRoute";
import Projects from "../pages/Projects";
import Company from "../pages/Company";
import CompanyFrom from "../pages/Company/CompanyForm";
import Users from "../pages/Users";
import UsersFrom from "../pages/Users/UsersForm";
import Department from "../pages/Department";
import DepartmentFrom from "../pages/Department/DepartmentForm";
import Zone from "../pages/Zone";
import ZoneFrom from "../pages/Zone/ZoneForm";
import SubZone from "../pages/SubZone";
import SubZoneFrom from "../pages/SubZone/SubZoneForm";
import ZoneLevel from "../pages/ZoneLevel";
import ZoneLevelFrom from "../pages/ZoneLevel/ZoneLevelForm";

import Dashboard from "../pages/Dashboard";
import Document from "../pages/Document";
import NotFound from "../pages/404";
import AddDocument from "../pages/AddDocument";
import Transmittal from "../pages/Transmittal";
import Submittal from "../pages/Submittal";

import CategoryList from "../pages/MetaData/Categories";
import CategoryForm from "../pages/MetaData/Categories/CategoriesForm";

import TypeList from "../pages/MetaData/Types";
import TypeForm from "../pages/MetaData/Types/TypesForm";

import StageList from "../pages/MetaData/Stage";
import StageForm from "../pages/MetaData/Stage/StageForm";
import IOdata from "../pages";

//   return routes;
const Routers: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<IOdata />} />
        {/* <Route path="/login" element={<Login />} /> */}
        <Route
          path="company"
          element={<ProtectedRoute path="company" component={Company} />}
        />
         <Route
          path="company/form"
          element={<ProtectedRoute path="company/form" component={CompanyFrom} />}
        />
        <Route
          path="users"
          element={<ProtectedRoute path="users" component={Users} />}
        />
         <Route
          path="users/form"
          element={<ProtectedRoute path="users/form" component={UsersFrom} />}
        />
        <Route
          path="department"
          element={<ProtectedRoute path="department" component={Department} />}
        />
         <Route
          path="department/form"
          element={<ProtectedRoute path="department/form" component={DepartmentFrom} />}
        />
        <Route
          path="/projects"
          element={<ProtectedRoute path="/projects" component={Projects} />}
        />
        <Route
          path="zone"
          element={<ProtectedRoute path="zone" component={Zone} />}
        />
         <Route
          path="zone/form"
          element={<ProtectedRoute path="zone/form" component={ZoneFrom} />}
        />
        <Route
          path="subzone"
          element={<ProtectedRoute path="subzone" component={SubZone} />}
        />
         <Route
          path="subzone/form"
          element={<ProtectedRoute path="subzone/form" component={SubZoneFrom} />}
        />
        <Route
          path="zonelevel"
          element={<ProtectedRoute path="zonelevel" component={ZoneLevel} />}
        />
         <Route
          path="zonelevel/form"
          element={<ProtectedRoute path="zonelevel/form" component={ZoneLevelFrom} />}
        />
        <Route
          path="/dashboard"
          element={<ProtectedRoute path="/dashboard" component={Dashboard} />}
        />
        <Route
          path="/document"
          element={<ProtectedRoute path="/projects/document" component={Document} />}
        />
        <Route
          path="/document/add"
          element={
            <ProtectedRoute path="/projects/document/add" component={AddDocument} />
          }
        />
        <Route
          path="transmittal"
          element={
            <ProtectedRoute path="transmittal" component={Transmittal} />
          }
        />
        <Route
          path="submittal"
          element={<ProtectedRoute path="submittal" component={Submittal} />}
        /> 
        <Route
          path="meta/categories"
          element={<ProtectedRoute path="meta/categories" component={CategoryList} />}
        />
         <Route
          path="meta/categories/form"
          element={<ProtectedRoute path="meta/categories/form" component={CategoryForm} />}
        />
         <Route
          path="meta/types"
          element={<ProtectedRoute path="meta/types" component={TypeList} />}
        />
         <Route
          path="meta/types/form"
          element={<ProtectedRoute path="meta/types/form" component={TypeForm} />}
        />
         <Route
          path="meta/stage"
          element={<ProtectedRoute path="meta/stages" component={StageList} />}
        />
         <Route
          path="meta/stage/form"
          element={<ProtectedRoute path="meta/stages/form" component={StageForm} />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default Routers;
