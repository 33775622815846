import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import {
  addCategory,
  getCategory,
  updateCategory,
} from '../../../lib/rest/categoriesAPIs';
import FormCtrl from '../../../components/Form';
import DialogBox from '../../../components/Dialog';

const StageForm: React.FC = () => {
  const [initialValues, setInitialValues] = useState({
    name: '',
    description: '',
  });
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState<'add' | 'update'>('add');
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.state && location.state.id) {
      setMode('update');
      getCategory({ id: location.state.id }).then((category) => {
        if (category?.length) {
          const { name, description } = category[0];
          setInitialValues({ name, description });
        }
      });
    }
  }, [location.state]);

  const validationSchema = Yup.object({
    name: Yup.string().required('Stage name is required')
  });

  const onSubmitHandler = async (values: {
    name: string;
    description: string;
  }) => {
    if (mode === 'add') {
     // await addCategory(values);
    } else {
    //  await updateCategory(location.state.id!, values);
    }
    setOpen(true);
  };

  const onBackHandler = () => {
    navigate('/meta/stages');
  };

  const handleClose = () => {
    setOpen(false);
    onBackHandler();
  };

  return (
    <>
      <FormCtrl
        title="Stage"
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmitHandler={onSubmitHandler}
        onBackHandler={onBackHandler}
        mode={mode}
      />
      <DialogBox
        open={open}
        title="Success"
        content={
          mode === 'add'
            ? 'The stage has been successfully added.'
            : 'The stage has been successfully updated.'
        }
        closeHandler={handleClose}
        actions={[{
          label: 'OK',
          handler: handleClose,
        }]}
      />
    </>
  );
};

export default StageForm;
