import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Typography,
  Button,
  Box,
  IconButton,
} from '@mui/material';
import {
  getCategories,
  uploadCSV,
  deleteCategory,
} from '../../../lib/rest/categoriesAPIs';
import { useNavigate } from 'react-router-dom';
import UploadCSV from '../../../components/Upload';
import DialogBox from '../../../components/Dialog';
import IOAlerts from '../../../components/Alert';
import IOTable from '../../../components/Table';
import { Edit, Delete } from '@mui/icons-material';

export interface Type {
  id: number;
  name: string;
  category: string;
  description?: string;
}
export interface CSVData {
  type: string;
  description?: string;
  category: string;
}
export interface CSVParsedData {
  name: string;
  category: string;
  description?: string;
}

const TypeList: React.FC = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState<Type[]>([]);
  const [search, setSearch] = useState(''); 
  const [dialog, setDialog] = useState({
    open: false,
    title: '',
    message: '',
    actions: [
      {
        label: 'cancel',
        handler: () => {},
      },
      {
        label: 'yes',
        handler: () => {},
      },
    ],
  });
  const [alert, setAlert] = useState<{
    open: boolean;
    severity: 'success' | 'error' | 'info' | 'warning';
    message: string;
  }>({
    open: false,
    severity: 'info',
    message: 'Loading...',
  });

  const closeDialogHandler = () => {
    setDialog((prevState) => ({ ...prevState, open: false }));
  };

  useEffect(() => {
    setDialog((prevState) => ({
      ...prevState,
      actions: [{ label: 'cancel', handler: closeDialogHandler }],
    }));
    //fetchCategories();
  }, []);

  const fetchCategories = async () => {
    setAlert({ open: true, message: 'Loading...', severity: 'info' });
    // const data = await getCategories();
    // setCategories(data);
    setAlert({ open: false, message: '', severity: 'info' });
  };

  const handleDelete = async (id: number) => {
    closeDialogHandler();
    setAlert({ open: true, message: 'Deleting...', severity: 'info' });
   // await deleteCategory(id.toString());
    //setCategories(categories.filter((category) => category.id !== id));
    setAlert({
      open: true,
      message: 'Deleted successfully',
      severity: 'success',
    });
  };

  const handleFormType = (id?: string) => {
    navigate('form', { state: { id } });
  };

  const fileHandler = async (fileData: CSVData[]) => {
    // const parsedData: CSVParsedData[] = fileData.map((item: any) => ({
    //   name: item.categories,
    //   description: item.description,
    // }));
    //console.log('handleUploadCSV-parsedData', parsedData);
    setAlert({ open: true, message: 'Uploading...', severity: 'info' });
    // await uploadCSV(parsedData);
    // fetchCategories();
    setAlert({
      open: true,
      message: 'Uploaded successfully',
      severity: 'success',
    });
  };

  const errorHandler = (error: Error) => {
    setAlert({ open: true, message: error.message, severity: 'error' });
  };

  const filteredTypes = categories
    .filter((category) =>
      category.name.toLowerCase().includes(search.toLowerCase()),
    )
    .sort((a, b) => a.name.localeCompare(b.name)
    );

  const headCells = [
    {
      id: 'name' as const,
      numeric: false,
      disablePadding: true,
      label: 'Name',
    },
    {
      id: 'category' as const,
      numeric: false,
      disablePadding: true,
      label: 'Category',
    },
    {
      id: 'description' as const,
      numeric: false,
      disablePadding: false,
      label: 'Description',
    },
    {
      id: 'actions' as const,
      numeric: false,
      disablePadding: false,
      label: 'Actions',
    },
  ] as const;

  const rows = filteredTypes.map((type: Type) => ({
    id: type.id,
    name: type.name,
    description: type.description,
      actions: (
        <>
          <IconButton
            aria-label="edit"
            onClick={() => handleFormType(type?.id.toString())}
          >
            <Edit />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() =>
              setDialog((prevState) => ({
                ...prevState,
                open: true,
                title: 'Delete',
                message: 'Are you sure you want to delete?',
                actions: [
                  { label: 'cancel', handler: closeDialogHandler },
                  {
                    label: 'yes',
                    handler: () => handleDelete(type.id),
                  },
                ],
              }))
            }
          >
            <Delete />
          </IconButton>
        </>
      ),
    }));

  const getDialog = () => {
    return (
      <DialogBox
        open={dialog.open}
        title={dialog.title}
        content={dialog.message}
        actions={dialog.actions}
        closeHandler={closeDialogHandler}
      />
    );
  };

  return (
    <>
      <IOAlerts alert={alert} setAlert={setAlert} />
      <Container>
        <Typography variant="h5">Type List</Typography>
        <Box display="flex" alignItems="center" mb={2}>
          <TextField
            label="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            fullWidth
            margin="normal"
            size="small"
          />
          <Button
            size="small"
            onClick={() => handleFormType()}
            style={{ marginLeft: 16 }}
          >
            Add
          </Button>
          <UploadCSV
            fileHandler={fileHandler}
            errorHandler={errorHandler}
            fileKeys={['name', 'description']}
          />
        </Box>
        <IOTable
          rows={rows}
          headCells={headCells}
          onDelete={(selected: readonly number[]) => {
            selected.forEach((id) => handleDelete(id));
          }}
        />
        {getDialog()}
      </Container>
    </>
  );
};

export default TypeList;
 