import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import {
  Dashboard,
  Assignment,
  AccountTree,
  Folder,
  TextSnippet,
  Person,
  Groups,
  BusinessCenter,
  Business,
  Source,
  Category,
  AlignHorizontalCenter,
  CenterFocusStrong,
  LocationOn,
  PinDrop,
  Flag,
  Tour,
  SportsScore
} from '@mui/icons-material';
import DescriptionIcon from '@mui/icons-material/Description';
import LayersIcon from '@mui/icons-material/Layers';
import { AppProvider } from '@toolpad/core/AppProvider';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import type { Router, Navigation } from '@toolpad/core';
import { Notifications } from '@mui/icons-material';

const NAVIGATION: Navigation = [
  {
    segment: 'dashboard',
    title: 'Dashboard',
    icon: <Dashboard />,
  },
  {
    segment: 'users',
    title: 'Users',
    icon: <Person />,
  },
  {
    segment: 'company',
    title: 'Enterprise/Company',
    icon: <Business />,
  },
  {
    segment: 'department',
    title: 'Department',
    icon: <Groups />,
  },
  {
    segment: 'projects',
    title: 'Projects',
    icon: <BusinessCenter />,
  },
  {
    segment: 'zone',
    title: 'Zone',
    icon: <Flag />,
  },
  {
    segment: 'subzone',
    title: 'Sub Zone',
    icon: <Tour />,
  },
  {
    segment: 'zonelevel',
    title: 'Zone Level',
    icon: <SportsScore/>,
  },
  {
    segment: 'document',
    title: 'Document',
    icon: <Assignment />,
  },
  // {
  //   segment: 'assets',
  //   title: 'Assets',
  //   icon: <Folder />,
  // },
  // {
  //   kind: 'divider',
  // },
  // {
  //   kind: 'header',
  //   title: 'Super Admin',
  // },
  
  {
    segment: 'meta',
    title: 'Metadata',
    icon: <Source />,
    children: [
      {
        segment: 'categories',
        title: 'Categories',
        icon: <Category />,
      },
      {
        segment: 'types',
        title: 'Types',
        icon: <AlignHorizontalCenter />,
      },
      {
        segment: 'stage',
        title: 'Stage',
        icon: <CenterFocusStrong />,
      },
      {
        segment: 'locations',
        title: 'Locations',
        icon: <LocationOn />,
      },
      {
        segment: 'sublocations',
        title: 'Sub Locations',
        icon: <PinDrop />,
      },
      {
        segment: 'contractors',
        title: 'Contractors',
        icon: <AccountTree />,
      },
      {
        segment: 'originators',
        title: 'Originators',
        icon: <TextSnippet />,
      },
      {
        segment: 'disciplines',
        title: 'Disciplines',
        icon: <DescriptionIcon />,
      },
      {
        segment: 'statuses',
        title: 'Statuses',
        icon: <DescriptionIcon />,
      },
    ],
  },
  {
    segment: 'integrations',
    title: 'Integrations',
    icon: <LayersIcon />,
  },
];

const theme = createTheme({
  cssVariables: true,
  palette: {
    mode: 'light', // or 'dark'
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: 140, // Adjust the width as needed
        },
      },
    },
  },
});

interface LayoutProps {
  children: React.ReactNode;
  path: string;
}

export default function Layout(props: LayoutProps) {
  const { children, path } = props;
  const navigate = useNavigate();

  const router = useMemo<Router>(() => {
    return {
      pathname: path,
      searchParams: new URLSearchParams(),
      navigate: (pathname) => navigate(String(pathname)),
    };
  }, [path]);

  console.log('router:', router);
  return (
    <AppProvider
      navigation={NAVIGATION}
      theme={theme}
      router={router}
      branding={{
        logo: <img src="/logo.png" alt="iOData logo" />,
        title: 'iOData',
      }}
    >
      <DashboardLayout
        slotProps={{
          toolbarActions: {
            children: (
              <IconButton aria-label="notifications">
                <Notifications />
              </IconButton>
            ),
          },
        }}
      >
        {children}
      </DashboardLayout>
    </AppProvider>
  );
}
