import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';

interface DialogProps {
  open: boolean;
  title: string;
  content: string;
  closeHandler: any;
  actions: any;
}

const DialogBox: React.FC<DialogProps> = (props: DialogProps) => {
  const { open, title, content, closeHandler, actions } = props;
  console.log('DialogBox-actions', actions);
  return (
    <Dialog open={open} onClose={closeHandler}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {actions.map((action: any, index: number) => (
          <Button key={index} onClick={action.handler} >
            {action.label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default DialogBox;
